<template>
  <app-layout>
    <template v-slot:navigationLeftDrawer>
      <inbox-drawer></inbox-drawer>
    </template>
  </app-layout>
</template>

<script>
import { baseHomeMixin } from "@/mixins/shared/base/home/baseHomeMixin";
export default {
  name: "InboxHome",
  mixins: [baseHomeMixin],
  components: {
    InboxDrawer: () => import("@/views/inbox/InboxDrawer")
  },
  computed: {
    selectApplicationName() {
      return this.applicationNames.Tasks;
    }
  }
};
</script>
